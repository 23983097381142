import React, { useRef, useState, useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import { AiOutlineHome, AiOutlineMail } from "react-icons/ai";
import { BiPhoneCall, BiInfoCircle } from "react-icons/bi";
import Container from "../components/Container";
import "./ContactNous.css";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const ContactNous = () => {

  const [formemail, setformemail] = useState(
    {

      user_name: '',
      user_message: '',

      user_email: '',
      user_mobile: '',



    }
  );

  const handleInput = (e) => {
    e.persist();
    setformemail({ ...formemail, [e.target.name]: e.target.value })
  }

 
  const [verified, setverified] = useState(false);
  const [t, i18n] = useTranslation();


  const handleClick = (event, message) => {
    // 👇️ refers to the image element

    message == 'fr' ? i18n.changeLanguage('fr') : i18n.changeLanguage('en');


  };

  const [emailcom, setemailcom] = useState('');
  const [resultat, setresultat] = useState('NO')
  const recaptchaRef = React.createRef();

  function onChange(value) {

    console.log("Captcha value:", value);
    setverified(true)
    //alert(recaptchaRef.current.getValue())
  }

  useEffect(() => {
    if (resultat == 'OK') {
      recaptchaRef.current.reset();
      setverified(false);
      setemailcom('Email Envoyé');
      setresultat('NO')
      setformemail({ ...formemail, 
        user_name: '',
        user_email:'',
        
        user_message:'',
        user_mobile:'',
    
    
    
    
    })
    }
  }, [resultat]);

  const form = useRef();





  const sendEmail = (e) => {
    e.preventDefault();



    setemailcom('...mail en cours d envoi')
    /*emailjs.sendForm(
      'service_hmi6pmo'
      , 'template_7t528tu'
      , form.current
      , 'DEJvE4Gfk8aR-F3AE')
      .then((result) => {
        console.log(result.text);
        //this.props.onSubmit(recaptchaValue);
        /* if(result.text=='OK'){
           recaptchaRef.current.reset();
           setverified(false);
           setemailcom('Email Envoyé');

         }
        setresultat(result.text);


      }, (error) => {
        setemailcom('Email non Envoyé');
      });*/
  };


  return (
    <>
      <Meta title={t('Contactez-nous')} />
      <BreadCrumb title={t('Implantation')} />
      <Container class1="contact-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3198.853844514343!2d3.1601980507913727!3d36.70204988082003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128e523410856bb5%3A0x494b8f4857a79b39!2sALTUB!5e0!3m2!1sfr!2sdz!4v1680534829148!5m2!1sfr!2sdz"
              width="600"
              height="450"
              className="border-0 w-100"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>


          <div className="contact-inner-wrapper d-flex justify-content-between mt-2" id="contactid">
            <div className="col-12 mt-5">
              <div className="row  py-5 justify-content-center ">

                <div className="col-sm-12	col-md-6	col-lg-6">
                  <h3 className="contact-title text-dark mb-4">{t('Contactez-nous')}</h3>




                  <form ref={form} onSubmit={sendEmail} className="d-flex flex-column gap-15">


                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('Name')}
                        name="user_name"
                        value={formemail.user_name}

                        onChange={handleInput}
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t('Email')}
                        name="user_email"
                        required
                        value={formemail.user_email}

                        onChange={handleInput}
                      />
                    </div>
                    <div>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder={t('Mobile-number')}
                        name="user_mobile"
                        required
                        value={formemail.user_mobile}

                        onChange={handleInput}
                      />
                    </div>
                    <div>
                      <textarea
                        name="user_message"

                        id=""
                        className="w-100 form-control"
                        cols="30"
                        rows="4"
                        placeholder={t('Comments')}
                        required
                        value={formemail.user_message}

                        onChange={handleInput}

                      ></textarea>
                    </div>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LfEcTYqAAAAAOpQP_WYXgvCqvv47LgS8gIPsmzS"
                      onChange={onChange}
                    />
                    <div>
                      <label className="form-label text-dark">{emailcom}</label>
                    </div>




                    <div className="col-sm-12	col-md-4	col-lg-4">
                      <motion.button
                        whileHover={{ scale: 0.9 }} whileTap={{ scale: 1 }} type="submit" class="btn buttonbleu pointeur rounded" value="Send" disabled={!verified}>{t('Send')}</motion.button>
                    </div>
                  </form>
                </div>
                <div className="col-sm-12	col-md-4	col-lg-4 ">
                  <h3 className="contact-title mb-4 text-dark">{t('Status')}</h3>
                  <div>
                    <ul className="ps-0 ">
                      <li className="mb-3 d-flex gap-15 align-items-center ">
                        <h5 className=" text-dark"></h5>
                      </li>
                      <li className="mb-3 d-flex gap-15 align-items-center ">
                        <h5 className=" text-dark">Sarl, ALTUB</h5>
                      </li>

                      <li className="mb-3 d-flex gap-15 align-items-center">

                        <AiOutlineHome className="fs-5" />

                        <address className="mb-0">
                          <span className=" text-dark">  </span>   {t('Adresse')}
                        </address>
                      </li>
                      <li className="mb-3 d-flex gap-15 align-items-center">
                        <BiPhoneCall className="fs-5" />
                        <a className=" text-dark">
                          <span className=" text-dark">  Tel : </span>   + 213 23 93 95 60
                        </a>
                      </li>

                      <li className="mb-3 d-flex gap-15 align-items-center">
                        <BiPhoneCall className="fs-5" />

                        <a className=" text-dark">
                          <span className=" text-dark">  Fax : </span>   + 213 23 93 95 65
                        </a>
                      </li>
                      <li className="mb-3 d-flex gap-15 align-items-center">
                        <BiPhoneCall className="fs-5" />

                        <a className=" text-dark">
                          <span className="text-dark">  Email : </span>  contact@altub-dz.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ContactNous;
