import React from "react";
import { Link } from "react-router-dom";
import { BsLinkedin, BsGithub, BsYoutube, BsInstagram } from "react-icons/bs";
import newsletter from "../images/newsletter.png";
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const [ t, i18n ] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element
   
    message=='fr' ?  i18n.changeLanguage('fr') :  i18n.changeLanguage('en');

    
  };


  return (
    <>
    
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-12	col-md-4	col-lg-4 justify-content-center">
              <h4 className="text-white mb-4">Contactez Nous</h4>
              <div>
                <address className="text-white fs-6">
                {t('Altub-ent')}<br></br>
                {t('Adresse')}
                </address>
                <a
                  //href="tel:+ 213 23 93 95 60"
                  className="mt-3 d-block mb-1 text-white"
                >
                  Tel : + 213 23 93 95 60
                  <br></br>
                  Fax: + 213 23 93 95 65

                </a>
                <a
                  //href="mailto:navdeepdahiya753@gmail.com"
                  className="mt-2 d-block mb-0 text-white"
                >
                  Email : contact@altub-dz.com
                </a>
                <div className="social_icons d-flex align-items-center gap-30 mt-4">
                  <a className="text-white" href="#">
                    <BsLinkedin className="fs-4" />
                  </a>
                  <a className="text-white" href="#">
                    <BsInstagram className="fs-4" />
                  </a>
                  <a className="text-white" href="#">
                    <BsGithub className="fs-4" />
                  </a>
                  <a className="text-white" href="#">
                    <BsYoutube className="fs-4" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12	col-md-3	col-lg-3">
              <h4 className="text-white mb-4">{t('Produits')}</h4>
              <div className="footer-link d-flex flex-column">
                <Link to="/privacy-policy" className="text-white py-2 mb-1">
                {t('Toles-Profilees-Et-Toles-Planes')}
                </Link>
                <Link to="/refund-policy" className="text-white py-2 mb-1">
                {t('Tubes-Moyens')}
                </Link>
                <Link to="/shipping-policy" className="text-white py-2 mb-1">
                {t('Tubes-Minces-Soudes')}
                </Link>
                
               
              </div>
            </div>
            <div className="col-sm-12	col-md-3	col-lg-3">
              
              <div className="footer-link d-flex flex-column">
                <Link className="text-white py-2 mb-1">Fiche Technique</Link>
                <Link className="text-white py-2 mb-1">Spécification Technique</Link>
                <Link  className="text-white py-2 mb-1">Galerie</Link>
              </div>
            </div>
            <div className="col-sm-12	col-md-2	col-lg-2">
              <h4 className="text-white mb-4">  {t('Qui-Sommes-Nous')}</h4>
              <div className="footer-link d-flex flex-column">
                <Link className="text-white py-2 mb-1">{t('MotdePresedent')}</Link>
                <Link className="text-white py-2 mb-1"> {t('Politique-Qualite')}</Link>
                <Link className="text-white py-2 mb-1"> Certification</Link>
               
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="py-4">
        <div className="container-xxl">
          <div className="row">
            <div className="col-12">
              <h6 className="text-center mb-0 text-white small">
                COPYRIGHT © 2023 BY GROUPE RAHMOUNE| DESIGNED & HOSTED BY :N.MECHENET
              </h6>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
