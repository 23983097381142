import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Container from "../components/Container";

import PoliyiqueQualititi from "../images/politique/PoliyiqueQualititi.png";


import { motion } from 'framer-motion';





//import { services } from "../utils/Data";

const PolitiqueQualitie = (props) => {



  const [t, i18n] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element

    message == 'fr' ? i18n.changeLanguage('fr') : i18n.changeLanguage('en');


  };

  return (


    <>






      <Container class1="wrapspacefich blog-wrapper py-5 home-wrapper-politique  ">
        <div className="row ">



          <div className="justify-content-center ">
         
            <div className="row mt-3">
              <div className="row col-12">
                <div className="card-image justify-content-center text-center">
                  <motion.img src={PoliyiqueQualititi} className="img-fluid w-50  " alt="blog" 
                  initial={{opacity:0, scale:0 }}
                  animate={{opacity:1, scale:1}}
                  transition={{duration:0.8}}
                  
                  />
                
                </div>
              </div>
          
             


            </div>

          </div>


        </div>

      






      </Container>

























    </>
  );
};

export default PolitiqueQualitie;
