import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Container from "../components/Container";

import bobine2 from "../images/bobine/bobine1.jpg";
import bobine1 from "../images/bobine/bobine2.jpg";
import bobine3 from "../images/bobine/bobine3.jpg";
import { HashLink } from 'react-router-hash-link';
import { motion } from "framer-motion";








//import { services } from "../utils/Data";

const SpecifTechnique = (props) => {

  let easeing = [0.6, -0.05, 0.01, 0.99];
  const animation1 = {
    initial: {
      y: -60,
      opacity: 0,
      transition: { duration: 0.6, ease: easeing }
    },
    animate: {
      y: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };


  const animation2 = {
    initial: {
      x: -60,
      opacity: 0,
      transition: { duration: 0.6, ease: easeing }
    },
    animate: {
      x: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };



  const [t, i18n] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element

    message == 'fr' ? i18n.changeLanguage('fr') : i18n.changeLanguage('en');


  };

  return (


    <>






      <Container class1="wrapspacefich blog-wrapper py-5 home-wrapper-fich  ">
 
        <div className="row ">



          <div className="justify-content-center justify-item-center ">
            <h2 className="text-white mb-4"> {t('Spécification-Technique')}</h2>

            <div className="row mt-3 pt-3 bg-light  roundedbg">
             <div className= "col-sm-12 col-md-4">
                <motion.div variants={animation1} initial='initial' animate='animate' className="card-image justify-content-center text-center">
                  <HashLink to="#poducta" className=" nav-link">
                    <img src={bobine1} className="img-fluid w-50  border-col" alt="blog" />


                    <h4 className="text-dark mb-4 mt-3 "> BOBINES GALVANISÉES</h4>
                  </HashLink>


                </motion.div>
              </div>
             <div className= "col-sm-12 col-md-4">
                <motion.div variants={animation1} initial='initial' animate='animate' className="card-image justify-content-center text-center">
                  <HashLink to="#poductb" className=" nav-link">
                    <img src={bobine2} className="img-fluid w-50  border-col" alt="blog" />



                    <h4 className="text-dark mb-4 mt-3"> BOBINES D'ACIER LAMINÉES À CHAUD</h4>
                  </HashLink>



                </motion.div>
              </div>
             <div className= "col-sm-12 col-md-4">
                <motion.div variants={animation1} initial='initial' animate='animate' className="card-image justify-content-center text-center">
                  <HashLink to="#poductc" className=" nav-link">

                    <img src={bobine3} className="img-fluid w-50  border-col" alt="blog" />





                    <h4 className="text-dark mb-4 mt-3"> BOBINES D'ACIER LAMINÉES À FROID</h4>
                  </HashLink>

                </motion.div>
              </div>


            </div>

          </div>


        </div>

        <motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poducta">



          <div className="justify-content-center ">

            <div className="row mt-3">
              <div className="row col-8">
                <motion.div className="card-image justify-content-center ">

                  <h4 className="text-dark mb-4 mt-3 "> BOBINES GALVANISÉES</h4>
                  <p className="text-dark mb-4 ">

                    <span className="text-dark">Nom du Matériau :</span>
                    Acier non allié laminé à chaud
                  </p>

                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">Domaine d'application :</span>
                    Les bobines, objets de cette spécification sont destinées à la fabrication des profilés à froid, des tubes soudés longitudinalement et les normes respectives NFA 37.101, NFA 49.141, NFA 49.142, NFA 49.145, NFA 49.150 et NFA 46.100.
                  </p>



                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">Propriétés mécaniques et chimiques :</span>
                    Les caractéristiques mécaniques et chimiques des aciers sont conformes à la norme NF EN 10025.
                  </p>



                </motion.div>
              </div>

             <div className= "col-sm-12 col-md-4">
                <div className="card-image justify-content-center text-center">
                  <img src={bobine1} className="img-fluid w-50  border-col" alt="blog" />
                  <h4 className="text-dark mb-4 mt-3"> BOBINES D'ACIER LAMINÉES À FROID</h4>
                </div>
              </div>


            </div>

          </div>


        </motion.div>

        <motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poductb">



          <div className="justify-content-center ">

            <div className="row mt-3">
              <div className="row col-8">
                <div className="card-image justify-content-center ">

                  <h4 className="text-dark mb-4 mt-3 "> BOBINES D'ACIER LAMINÉES À CHAUD</h4>
                  <p className="text-dark mb-4 ">

                    <span className="text-dark">DESIGNATION DU PRODUIT :</span>
                    Bobine galvanisée issue d'une ligne de galvanisation à chaud par immersion dans un bain de zinc en fusion
                  </p>


                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">Domaine d'application :</span>
                    Les bobines, objets de cette spécification sont destinées à la fabrication des profilés à froid, des tubes soudés longitudinalement et les normes respectives NFA 37.101, NFA 49.141, NFA 49.142, NFA 49.145, NFA 49.150 et NFA 46.100.
                  </p>



                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">APPLICATION : </span> <br></br>
                    Profilage et pliage (dans le bâtiment, construction, transport, équipement agricole, mobilier…)
                  </p>

                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">NORMES : </span>
                    Classe I selon NFA 36 321, équivalent à FePO2G (DX51D) selon EN 10327
                  </p>

                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">TOLERANCES DIMENSIONNELLES : </span>
                    Selon NFA 46 323 équivalent à EN 10143
                  </p>



                </div>
              </div>

             <div className= "col-sm-12 col-md-4">
                <div className="card-image justify-content-center text-center">
                  <img src={bobine2} className="img-fluid w-50  border-col" alt="blog" />
                  <h4 className="text-dark mb-4 mt-3"> BOBINES D'ACIER LAMINÉES À FROID</h4>
                </div>
              </div>


            </div>

          </div>


        </motion.div>


        <motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poductc">



          <div className="justify-content-center ">

            <div className="row mt-3">
              <div className="row col-8">
                <div className="card-image justify-content-center ">

                  <h4 className="text-dark mb-4 mt-3 "> BOBINES  D'ACIER LAMINÉES À FROID</h4>
                  <p className="text-dark mb-4 ">

                    <span className="text-dark">OBJET ET DOMAINE D'APPLICATION : </span><br></br>
                    Les bobines, objets de cette spécification, devront satisfaire les exigences de la norme EN 10130; et sont destinées à la fabrication des tubes soudés longitudinalement suivant les normes : NF EN 10305-3 / NF EN 10305-5.
                  </p>


                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">SPECIFICATION DE LA COMMANDE :</span><br></br>
                    1. Nom du matériau : Acier non allié à froid.<br></br>
                    2. Forme : Bobine Livrée En Largeur Simple Sans Refondage..
                  </p>







                </div>
              </div>

             <div className= "col-sm-12 col-md-4">
                <div className="card-image justify-content-center text-center">
                  <img src={bobine3} className="img-fluid w-50  border-col" alt="blog" />
                  <h4 className="text-dark mb-4 mt-3"> BOBINES D'ACIER LAMINÉES À FROID</h4>
                </div>
              </div>


            </div>

          </div>


        </motion.div>
      </Container>

























    </>
  );
};

export default SpecifTechnique;
