import React, { useState, useRef, useEffect } from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";

import { BiPhoneCall, BiInfoCircle } from "react-icons/bi";
import Container from "../components/Container";
import "./Recrutement.css";
import { useTranslation } from "react-i18next";
import { motion } from 'framer-motion';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

import "react-widgets/styles.css";

import Combobox from "react-widgets/Combobox";

const Recrutement = () => {
  const [t, i18n] = useTranslation();
  const [nom, setName] = useState('');
  const [emaill, setEmail] = useState('');
  const [poste, setPoste] = useState('');
  const [tell, setTel] = useState('');
  const [tsenvoi, setTsenvoi] = useState('');


  const [message, setMessage] = useState('');
  const [file, setFile] = useState()
  const [sstart, setSstart] = useState(false)

  const previewRef = useRef(null);

  const [imageUrl, setImageUrl] = useState("");


  const [resultat, setresultat] = useState('NO')
  const recaptchaRef = React.createRef();
  const [verified, setverified] = useState(false);

  function onChange(value) {

    console.log("Captcha value:", value);
    setverified(true)
    //alert(recaptchaRef.current.getValue())
  }



  function handleChange(event) {
    setFile((event.target.files[0]))


  }

  useEffect(() => {
    if (resultat == 'OK') {
      recaptchaRef.current.reset();
      //setverified(false);

      //setresultat('NO')

    }
  }, [resultat]);

  const handleSubmit = () => {

    if (nom.length === 0) {
      alert("Nom et le Le Prénom obligatoire!")

    }


    else if (emaill.length === 0) {
      alert("Emaill et Obligatoire!")

    }

    else if (poste.length === 0) {
      alert("Poste obligatoire!")

    }
    else if (tell.length === 0) {
      alert("Telephone et Obligatoire")

    }
    else if (file.length === 0) {
      alert("CV  et Obligatoire")
    }
    else {
      setSstart(true)
      //const url = "http://localhost:88/sendemail/send-email.php";
      const url = "https://altub-dz.com/phpemail/send-email.php";
      let fData = new FormData();
      fData.append('name', nom);
      fData.append('email', emaill);
      fData.append('message', 'Condidature pour le Poste : ' + poste + '  Numéro de téléphone : ' + tell + '  Email : ' + emaill + '  Message : ' + message);
      fData.append('tel', tell);
      fData.append('poste', poste);
      fData.append('subject', 'Condidature pour le Poste ' + poste);
      fData.append('file[]', file);
      //fData.append('fileName', file.name);








      setTsenvoi("...mail en cours d'Envoi")
      axios.post(url, fData)
        .then(response => {


          alert('Votre cv et Envoyer');
          setTsenvoi("Votre cv et Envoyer   .....")
          setName('');
          setTel('');

          setEmail('');
          setFile('');
          setMessage('');
          setPoste('');


          setverified(false);

          setresultat('NO')



        })
        .catch(error => alert(error));

    }
  }
  return (
    <>


      <Meta title="ALTUB - Société Algérienne De Fabrication De Tubes" />



      <BreadCrumb title={t('Recrutement')} />

      <Container class1="contact-wrapper  ">
        <div className="row">




          <div className="contact-inner-wrapper d-flex justify-content-between mt-1">
            <div className="col-12">
              <div className="row  py-0 justify-content-center ">

                <div className="col-sm-12	col-md-12	col-lg-12">
                  <h3 className="contact-title mb-0">Contact</h3>

                  <form action="" className="d-flex flex-column gap-30">
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('Name')}
                        id="nom"
                        value={nom}

                        onChange={(e) => setName(e.target.value)}

                      />
                    </div>
                    <div>
                      <input
                        type="emaill"
                        className="form-control"
                        placeholder={t('emaill')}
                        id="emaill"

                        value={emaill}

                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <Combobox
                        defaultValue=""
                        data={["Ingenieur ou TS en Electrotechnique / Electricité",
                               "Chargé de Moyens Généraux", 
                            
                               "Assistance de Direction",
                              "Chef de Parc",
                            "Responsable de Maintenance Industrielle",
                          "Magasinier / Aide Magasinier",
                          "Acheteur Démarcheur",
                          "Responsable de Production",
                          "Soudeur Qualifié",
                          "Responsable des Approvisionnements",
                          "Acheteur Démarcheur"

                        ]}
                 
                          placeholder={t('PosteT')}


                          value={poste}
  
                          onChange={poste => setPoste(poste)}
                          className="form-control"
                      />;
                    </div>


            
                    <div>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder={t('Mobile-number')}
                        id="tell"
                        value={tell}

                        onChange={(e) => setTel(e.target.value)}
                      />
                    </div>
                    <div>
                      <textarea
                        name=""
                        id=""
                        className="w-100 form-control"
                        cols="30"
                        rows="4"
                        placeholder={t('Comments')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div>
                      <label for="formFile" className="form-label text-dark">CV*</label>
                    </div>
                    <div>

                      <input name="file[]" multiple="multiple" class="form-control" type="file" id="file" onChange={handleChange}></input>
                    </div>


                    <div>
                    

                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfEcTYqAAAAAOpQP_WYXgvCqvv47LgS8gIPsmzS"
                        onChange={onChange}
                      />

                    <div>
                      <label for="formFile" className="form-label text-dark">{tsenvoi}</label>
                    </div>

                      <div className="col-sm-12	col-md-4	col-lg-4">
                        <motion.button disabled={verified === false}
                          whileHover={{ scale: 0.9 }} whileTap={{ scale: 1 }} type="button" class="btn buttonbleu pointeur rounded" onClick={handleSubmit} >{t('Send')}</motion.button>
                      </div>


                    </div>
                  </form>


                </div>


              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Recrutement;
