import React from "react";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import imageBan from "../images/main-banner-1.jpg";
import imageKan from "../images/catbanner-01.jpg";
import imageKan2 from "../images/catbanner-03.jpg";
import imageKan1 from "../images/catbanner-04.jpg";
import imageKan3 from "../images/catbanner-02.jpg";

import algematcologo from "../images/logoE/algematco.png";
import arcometalcologo from "../images/logoE/arcometal.png";
import saftraillogo from "../images/logoE/saftrail.png";
import altublogo from "../images/logoE/altub.png";

import Landingpage from "../components/Landingpage";
import { useTranslation } from 'react-i18next';
import BlogCardnew from "../components/BlogCardnew";
import { useState } from "react";
import BlogProduit from "../components/BlogProduit";
import BlogEvenements from "../components/BlogEvenements";
import ContactNous from "./ContactNous";
import Galerie from "./Galerie";
import Recrutement from "./Recrutement";
import MPrecident from "./MPrecident";
import Galerieslide from "./Galerieslide";
import Contacttest from "./Contacttest";
import {motion} from 'framer-motion';
import {IconContext} from "react-icons";
import {FaBehance, FaDribbble} from 'react-icons/fa';
import {IoMailOutline, IoChevronForwardCircle, IoStar} from 'react-icons/io5';
import './Pubacc.css';
import batimetecc from "../images/bgground/batimatec.png"

let easeing = [0.6,-0.05,0.01,0.99];

const stagger = {
  animate:{
    transition:{
      delayChildren:0.4,
      staggerChildren:0.2,
      staggerDirection:1
    }
  }
}

const fadeInUp = {
  initial:{
    y:-60,
    opacity:0,
    transition:{
      duration:0.6, ease:easeing
    }
  },
  animate:{
    y:0,
    opacity:1,
    transition:{
      duration:0.6,
      delay:0.5,
      ease:easeing
    }
  }
};

const transition = {duration:1.4,ease:[0.6,0.01,-0.05,0.9]};

const firstName = {
  initial:{
    y:-20,
  },
  animate:{
    y:0,
    transition:{
      delayChildren:0.4,
      staggerChildren:0.04,
      staggerDirection:-1
    }
  }
}

const lastName = {
  initial:{
    y:-20,
  },
  animate:{
    y:0,
    transition:{
      delayChildren:0.4,
      staggerChildren:0.04,
      staggerDirection:1
    }
  }
}

const letter = {
  initial:{
    x:-900,
  },
  animate:{
    x:0,
    transition:{duration:1, ...transition}
  }
};

const btnGroup={
  initial:{
    y:-60,
    opacity:0,
    transition:{duration:0.6, ease:easeing}
  },
  animate:{
    y:0,
    opacity:1,
    animation:{
      duration:0.6,
      ease:easeing
    }
  }
};
const star={
  initial:{
    y:60,
    opacity:0,
    transition:{duration:0.8, ease:easeing}
  },
  animate:{
    y:0,
    opacity:1,
    animation:{
      duration:0.6,
      ease:easeing
    }
  }
};

const header={
  initial:{
    y:-60,
    opacity:0,
    transition:{duration:0.05, ease:easeing}
  },
  animate:{
    y:0,
    opacity:1,
    animation:{
      duration:0.6,
      ease:easeing
    }
  }
};



//import { services } from "../utils/Data";

const Pubacc = (props) => {





  return (


    <>

<motion.div initial='initial' animate='animate'>


      <motion.div className="content_wrapper" initial={{opacity:0,scale:0}} animate={{opacity:1, scale:1}} transition={{duration:0.3, ease:easeing}}>

      <div className="left_content_wrapper">
   

<motion.h2>

  <motion.span variants={firstName} initial="initial" animate="animate" className='first'>
      

      
  </motion.span>
  
</motion.h2>

</div> 
      <motion.div className="content_wrapperimg" initial={{opacity:0,scale:0}} animate={{opacity:1, scale:1}} transition={{duration:0.3, ease:easeing}}>
      


    
         <img src={batimetecc}  alt="ALTUB"></img>


      
        </motion.div>
      </motion.div>

     



    </motion.div>







    </>
  );
};

export default Pubacc;
