import React from "react";
import { Link } from "react-router-dom";
import "./BlogCard.css";
import new1 from "../images/news/new1.png";
import new2 from "../images/news/new2.png";
import new3 from "../images/news/new3.png";

const BlogCard = () => {
  return (
    <>
  
  
    
    <div className="blog-cardnew">
      <div className="blog-contentnew ">

        <h5 className="title text-center contact-title ">News</h5>


      </div>
      <div className="blog-cardnenew">

      <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
  <div className="carousel-indicators d-none">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src={new1} className="d-block  iamgeslide" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5></h5>
        <p className="text-white small">ALTUB sera en arrêt de production le 15 Avril 2024 pour cause d’inventaire.</p>
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src={new2} className="d-block iamgeslide" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5></h5>
        <p className="text-white small">ALTUB organisera une formation interne pour les opérateurs du 15 au 20 janvier 2024.</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={new3} className="d-block iamgeslide" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5></h5>
        <p className="text-white small">ALTUB organisera un séminaire sur le suivi de la norme iso le 25 Avril.</p>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

  
     
       
       
      </div>
    </div>
    </>
  );
};

export default BlogCard;
