import React from "react";
import { Link } from "react-router-dom";
import "./BlogProduit.css";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { HashLink } from "react-router-hash-link";
import CustomButon from "./CustomButon";

const BlogProduit = () => {
  const [ t, i18n ] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element
   
    message=='fr' ?  i18n.changeLanguage('fr') :  i18n.changeLanguage('en');

    
  };
  return (
    <div className="blog-cardnew">
      <div className="blog-contentnew ">

        <h5 className="title text-center contact-title ">  {t('Produits')}</h5>


      </div>
      <div className="blog-cardProduit">
        <div>
          <ul className="ps-0 ">
            <li >{t('Tubes-Moyens')}</li>
            <li >{t('Toles-Profilees-Et-Toles-Planes')}</li>
            <li >{t('Tubes-Minces-Soudes')}</li>
            
          </ul>
        </div>
       
        <div className="blog-cardProduitbt">

         
        
        </div>
        <div className="row  col-md-6 ms-md-2 col-ms-6  justify-content-left">
          <HashLink to="product">
            <CustomButon text= {t('Read-More')} classnamecolor="bg-primary text-light" animationn="3" ></CustomButon>
            </HashLink>
          </div>
       

      </div>
    </div>
  );
};

export default BlogProduit;
