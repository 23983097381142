import React from "react";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import imageBan from "../images/main-banner-1.jpg";
import imageKan from "../images/catbanner-01.jpg";
import imageKan2 from "../images/catbanner-03.jpg";
import imageKan1 from "../images/catbanner-04.jpg";
import imageKan3 from "../images/catbanner-02.jpg";

import algematcologo from "../images/logoE/algematco.png";
import arcometalcologo from "../images/logoE/arcometal.png";
import saftraillogo from "../images/logoE/saftrail.png";
import altublogo from "../images/logoE/altub.png";

import Landingpage from "../components/Landingpage";
import { useTranslation } from 'react-i18next';
import BlogCardnew from "../components/BlogCardnew";
import { useState } from "react";
import BlogProduit from "../components/BlogProduit";
import BlogEvenements from "../components/BlogEvenements";
import ContactNous from "./ContactNous";
import Galerie from "./Galerie";
import Recrutement from "./Recrutement";
import MPrecident from "./MPrecident";
import Galerieslide from "./Galerieslide";
import Contacttest from "./Contacttest";
import { motion } from "framer-motion";
import TestEmail from "./TestEmail";



//import { services } from "../utils/Data";

const HomeNew = (props) => {

  const [flowers, setFlower] = useState([
    {
      'id': 1,
      'title': 'red',
      'title_ar': 'ناتناتات',

    },
    {
      'id': 2,
      'title': 'red',
      'title_ar': 'ناتناتات',

    },
    {
      'id': 3,
      'title': 'red',
      'title_ar': 'ناتناتات',

    }


  ])



  return (


    <>




      <Landingpage />

      <Container class1="wrapspace blog-wrapper    ">
   
        <div className="row ">
        <motion.div className=" col-sm-12	col-md-4	col-lg-4"
        
        initial={{opacity:0, scale:0 }}
        animate={{opacity:1, scale:1}}
        transition={{duration:0.9}}
        
        >

            <BlogCard />
          </motion.div>
          <motion.div className="col-sm-12	col-md-4	col-lg-4"
          
          initial={{opacity:0, scale:0 }}
          animate={{opacity:1, scale:1}}
          transition={{duration:0.9}}
          >

            <BlogProduit />
          </motion.div>
          <motion.div className="col-sm-12	col-md-4	col-lg-4"
             initial={{opacity:0, scale:0 }}
             animate={{opacity:1, scale:1}}
             transition={{duration:0.9}}
          
          >

            <BlogEvenements />
          </motion.div>

        </div>
      </Container>


    <div id="implantationid">

      <Container  class1="marque-wrapper home-wrapper-4 py-2 mt-2">
<motion.div

initial={{opacity:0, scale:0 }}
animate={{opacity:1, scale:1}}
transition={{duration:0.9}}

>

<ContactNous></ContactNous>

</motion.div>
       
        </Container>
        </div>
        
<div  id="motdepresidentid">
        <Container class1="marque-wrapper home-wrapper-2 py-2 mt-2">

<MPrecident></MPrecident>
</Container>
</div>
<div  id="calerieid">
        <Container class1="marque-wrapper home-wrapper-2 py-2 mt-2">

        <Galerieslide></Galerieslide>
</Container>
</div>





<div  id="recrutementid">
<Container class1="marque-wrapper home-wrapper-5 py-2 mt-2">

<Recrutement></Recrutement>

</Container>

</div>


      <Container class1="marque-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="marquee-inner-wrapper ">
              <Marquee className="d-flex" pauseOnHover={true}>
             
                <div className="mx-4 w-25">
                  <img src={algematcologo} className="w-25" alt="altub" />
                </div>
                <div className="mx-4 w-25">
                  <img src={arcometalcologo} className="w-25" alt="altub" />
                </div>
                <div className="mx-4 w-25">
                  <img src={altublogo} className="w-25" alt="altub" />
                </div>
                <div className="mx-4 w-25">
                  <img src={saftraillogo} className="w-25" alt="altub" />
                </div>
                <div className="mx-4 w-25">
                  <img src={algematcologo} className="w-25" alt="altub" />
                </div>
                <div className="mx-4 w-25">
                  <img src={arcometalcologo}  className="w-25" alt="altub" />
                </div>
                <div className="mx-4 w-25">
                  <img src={altublogo} className="w-25" alt="altub" />
                </div>
                <div className="mx-4 w-25">
                  <img src={saftraillogo} className="w-25" alt="altub" />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </Container>








    </>
  );
};

export default HomeNew;
