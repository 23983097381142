import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import HeaderNew from "./HeaderNew";
import {motion} from 'framer-motion';
import Galerieslide from '../pages/Galerieslide';



const Layout = () => {

  let easeing = [0.6,-0.05,0.01,0.99];
  const stagger = {
    animate:{
      transition:{
        delayChildren:0.4,
        staggerChildren:0.2,
        staggerDirection:1
      }
    }
  }
  return (
    <>
  <motion.div initial='initial' animate='animate'>
     <motion.div variants={stagger} animate='animate'>
      <HeaderNew />
      </motion.div>
      <Outlet />
      <Footer />
   
      </motion.div>
     
    </>
  );
};

export default Layout;
