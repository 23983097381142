import React, { useState } from "react";
import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";

import Contact from "./pages/Contact";


import CompareProduct from "./pages/CompareProduct";
import Wishlist from "./pages/Wishlist";
import Login from "./pages/Login";
import Forgotpassword from "./pages/Forgotpassword";




import TermAndContions from "./pages/TermAndContions";


import Checkout from "./pages/Checkout";
import HomeNew from "./pages/HomeNew";
import BlogCardnew from "./components/BlogCardnew";
import FicheTechnique from "./pages/FicheTechnique";
import SpécifTechnique from "./pages/SpecifTechnique";
import SpecifTechnique from "./pages/SpecifTechnique";
import PolitiqueQualitie from "./pages/PolitiqueQualitie";
import Certification from "./pages/Certification";
import QuiSomeNous from "./pages/QuiSomeNous";
import Pubacc from "./pages/Pubacc";
import {motion} from 'framer-motion';
import Product from "./pages/Product";
import TestEmail from "./pages/TestEmail";
import QuiSomeNousEng from "./pages/QuiSomeNousEng";

function App(props) {
 
  return (
    <>

    <motion.div  
    initial={{opacity:1, display:'block'}}
    animate={{opacity:0,height:'0px' }}
    transition= {{ duration: 0.2, delay:4}}
    
    
    >

  </motion.div>
  <motion.div 
    initial={{opacity:0,height:'0%'}}
    animate={{opacity:1,height:'100%' }}
    transition= {{ duration: 0.2 , delay:4}}
    
    
    
    >
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
      
            <Route index element={<HomeNew />} />
        
            <Route path="Home" element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="product" element={<Product />} />
       
            
          
  
            <Route path="checkout" element={<Checkout />} />
            <Route path="compare-product" element={<CompareProduct />} />
            <Route path="wishlist" element={<Wishlist />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<Forgotpassword />} />
         
           <Route path="term-conditions" element={<TermAndContions />} />
            <Route path="fiche-technique" element={<FicheTechnique />} />
            <Route path="specif-technique" element={<SpecifTechnique />} />
            <Route path="politique-qualitie" element={<PolitiqueQualitie />} />
            <Route path="certification" element={<Certification />} />
            <Route path="quisomenous" element={<QuiSomeNous />} />
            <Route path="testemail" element={< TestEmail/>} />
            
          
             <Route path="quisomenouseng" element={<QuiSomeNousEng />} />
            
          </Route>
        </Routes>
      </HashRouter>
      </motion.div>
    </>
  );
}

export default App;
