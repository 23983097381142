import React from "react";
import { Link } from "react-router-dom";
import "./BlogEvenements.css";
import evenementimg from  "../images/bgground/batimatec.png";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { HashLink } from "react-router-hash-link";
import CustomButon from "./CustomButon";


const BlogCard = () => {
  const [ t, i18n ] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element
   
    message=='fr' ?  i18n.changeLanguage('fr') :  i18n.changeLanguage('en');

    
  };
  return (
    <div className="blog-cardnew">
      <div className="blog-contentnew ">

        <h5 className="title text-center contact-title ">{t('Evenements')}</h5>


      </div>
      <div className="blog-cardnenew">
        <div className="card-image  ">
          <img src={evenementimg} className="img-fluid w-100" alt="blog" />
        </div>
        <div className="blog-contentnew">
        <div className="row  col-md-6 ms-md-2 col-ms-6  justify-content-left">
          <HashLink to="product">
            <CustomButon text= {t('Read-More')} classnamecolor="bg-primary text-light" animationn="3" ></CustomButon>
            </HashLink>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
