


import React from "react";
import { Link } from "react-router-dom";
import { HashLink  } from 'react-router-hash-link';
import Vediop from '../media/altub.mp4';
import Container from "./Container";
import { motion } from 'framer-motion';
import CustomButon from "./CustomButon";
import { useTranslation } from 'react-i18next';
import logoiso from "../images/svg/logoiso.jpg";

import './Landingpage.css';
import LogoSvg from "./LogoSvg";
import { NavLink } from "react-router-dom";
const Landingpage = () => {
  const [t, i18n] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element

    message == 'fr' ? i18n.changeLanguage('fr') : i18n.changeLanguage('en');


  };

  let easeing = [0.6, -0.05, 0.01, 0.99];

  const stagger = {
    animate: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    }
  }

  const fadeInUp = {
    initial: {
      y: -60,
      opacity: 0,
      transition: {
        duration: 0.6, ease: easeing
      }
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: 0.5,
        ease: easeing
      }
    }
  };

  const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

  const firstName = {
    initial: {
      y: -20,
    },
    animate: {
      y: 0,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.04,
        staggerDirection: -1
      }
    }
  }

  const lastName = {
    initial: {
      y: -20,
    },
    animate: {
      y: 0,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.04,
        staggerDirection: 1
      }
    }
  }

  const letter = {
    initial: {
      y: 400,
    },
    animate: {
      y: 0,
      transition: { duration: 1, ...transition }
    }
  };

  const btnGroup = {
    initial: {
      y: -60,
      opacity: 0,
      transition: { duration: 0.6, ease: easeing }
    },
    animate: {
      y: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };
  const star = {
    initial: {
      y: 60,
      opacity: 0,
      transition: { duration: 0.8, ease: easeing }
    },
    animate: {
      y: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };

  const header = {
    initial: {
      y: -60,
      opacity: 0,
      transition: { duration: 0.05, ease: easeing }
    },
    animate: {
      y: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };






  return (

    <motion.div className="landingpage" initial='initial' animate='animate'
    /*initial={{ opacity: 0, height: 0 }}
    animate={{ opacity: 1, height: "100vh" }}
    transition={{ duration: 1, ease: easing }}*/
    >


      < video
        src={Vediop}
        autoPlay muted loop className="video-bg"

      />


      <div className="bg-overlay"></div>



      <div


        className="home-text">




        <div className="row  justify-content-center justify-content-center">
          <div className="col-8 col-md-8 col-sm-12  col-sm-12 me-md-3">

            <motion.h1 variants={header} initial="initial" animate="animate" className="d-flex d-md-flex d-sm-flex">

              ALTUB


            </motion.h1>

          </div>
          <div className="col-2 col-md-2 col-sm-0 mt-5 diplayclass">


        <motion.img  className="img-fluid w-50   d-md-flex d-sm-none  "  alt=""
        
        initial={{opacity:0, scale:0 }}
        animate={{opacity:1, scale:1}}
        transition={{duration:0.8}}
        
        
        
        />
        
          </div>

        </div>


        <motion.div

          className="bg-tree"
          
          initial={{opacity:0, scale:0 }}
          animate={{opacity:1, scale:1}}
          transition={{duration:0.8}}
          
          ></motion.div>
        <div  >
          <motion.p className="home-textSecond mx-2 fw-bold" variants={fadeInUp}>{t('Altub-ent')}</motion.p>
        </div>
        <div className="row ">
      
          <div className="  col-md-5 col-ms-12  mx-2 my-2    justify-content-center">

          <Link to={"quisomenous"}>
            <CustomButon text={t('Qui-Sommes-Nous')} classnamecolor="btn-danger col-12 px-4"  animationn="4"></CustomButon>
            </Link>
             </div>
          <div className="  col-md-5  mx-2 my-2 col-ms-12  justify-content-center">
          <HashLink to="/#recrutementid" className="text-dark">
            <CustomButon text={t('Recrutement')} classnamecolor="btn-light  col-12 px-4 py-md-3  " animationn="3" ></CustomButon>
            </HashLink>
          </div>
        </div>


      </div>








    </motion.div>






  );
};

export default Landingpage;
