import React from "react";

import { AiOutlineHome, AiOutlineMail } from "react-icons/ai";
import { BiPhoneCall, BiInfoCircle } from "react-icons/bi";

import diapo1 from  "../images/galerie/diapo1.jpg";
import Meta from "../components/Meta";
import diapo2 from  "../images/galerie/diapo2.jpg";
import diapo3 from  "../images/galerie/diapo3.jpg";
import diapo4 from  "../images/galerie/diapo4.jpg";
import diapo5 from  "../images/galerie/diapo5.jpg";
import diapo6 from  "../images/galerie/diapo6.jpg";
import diapo7 from  "../images/galerie/diapo7.jpg";
import diapo8 from  "../images/galerie/diapo8.jpg";
import diapo9 from  "../images/galerie/diapo9.jpg";
import diapo10 from  "../images/galerie/diapo10.jpg";
import diapo11 from  "../images/galerie/diapo11.jpg";
import diapo12 from  "../images/galerie/diapo12.jpg";
import Lightroom from 'react-lightbox-gallery'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { useState } from "react";
import { Container } from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion";






const Galerieslide = () => {
  const [ t, i18n ] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element
   
    message=='fr' ?  i18n.changeLanguage('fr') :  i18n.changeLanguage('en');

    
  };

  var images = [
    {
      src:diapo1,
      desc: ".",
      sub: "."
    },
    {
      src:diapo2,
      desc: ".",
      sub: "."
    },
    {
      src:diapo3,
      desc: ".",
      sub: "."
    },
    {
      src:diapo4,
      desc: ".",
      sub: "."
    },
    {
      src:diapo5,
      sub: "."
    },
    {
      src:diapo6,
      desc: ".",
      sub: "."
    },
    {
      
      src:diapo7,
      sub: "."
    },
    {
      src:diapo8,
      sub: "."
    },
    {
      src:diapo9,
      sub: "."
    },
    {
      src:diapo10,
      sub: "."
    },
    {
      src:diapo11,
      sub: "."
    },
    {
      src:diapo12,
      sub: "."
    }
  ];
  var settings = {
    columnCount: {
      default: 3,
      mobile: 3,
      tab: 4
    },
    mode: "dark",
   
  };

  return (
    <>
      <Meta title={t('Galerie')} />
      <BreadCrumb classnamecolor="text-dark" title={t('Galerie')}  />

    <Container class1="description-wrapperprecedent py-5 home-wrapper-2">
           <div className="row">
           <motion.div className="col-12"
          
           
           >
    <Lightroom images={images}   settings={settings} 
    
    
    />
  </motion.div>
  </div>
 
  </Container>
  </>
  );
};

export default Galerieslide;
