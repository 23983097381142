import React from "react";
import { motion } from 'framer-motion';

const CustomButon = (props) => {
  const { text, classnamecolor, animationn } = props;

  let easeing = [0.6,-0.05,0.01,0.99];
  const animation1={
    initial:{
      y:-60,
      opacity:0,
      transition:{duration:0.6, ease:easeing}
    },
    animate:{
      y:0,
      opacity:1,
      animation:{
        duration:0.6,
        ease:easeing
      }
    }
  };


  const animation2={
    initial:{
      y:60,
      opacity:0,
      transition:{duration:0.8, ease:easeing}
    },
    animate:{
      y:0,
      opacity:1,
      animation:{
        duration:0.6,
        ease:easeing
      }
    }
  };

  const animation3={
    initial:{
      x:160,
      opacity:0,
      transition:{duration:0.8, ease:easeing}
    },
    animate:{
      x:0,
      opacity:1,
      animation:{
        duration:0.6,
        ease:easeing
      }
    }
  };
  
  const animation4={
    initial:{
      x:-160,
      opacity:0,
      transition:{duration:0.8, ease:easeing}
    },
    animate:{
      x:0,
      opacity:1,
      animation:{
        duration:0.6,
        ease:easeing
      }
    }
  };
  const anm = props.animationn==3 ? animation3: props.animationn==4 ? animation4 : props.animationn==2 ? animation2 :  props.animationn==1 ? animation1 :null;


  const classname= props.classnamecolor ? " btn " +props.classnamecolor+"  pointeur shadow-lg   rounded"
                    : "btn    pointeur shadow-lg   rounded" ;


  return (



<>
<motion.button  variants={ anm} initial= 'initial' animate='animate'
whileHover={{ scale: 0.9 }} whileTap={{ scale: 1 }}
type="button" class={classname}>{ text }</motion.button>
</>
  );
};

export default CustomButon;
