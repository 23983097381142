import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Container from "../components/Container";

import bobine1 from "../images/produit/Toles-Profilees.jpg";
import bobine2 from "../images/produit/Tubes-Moyens-Soudes.jpg";
import bobine3 from "../images/produit/Tubes-Rectangulaires.jpg";
import { HashLink } from 'react-router-hash-link';
import { motion } from "framer-motion";








//import { services } from "../utils/Data";

const Product = (props) => {

  let easeing = [0.6, -0.05, 0.01, 0.99];
  const animation1 = {
    initial: {
      y: -60,
      opacity: 0,
      transition: { duration: 0.6, ease: easeing }
    },
    animate: {
      y: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };


  const animation2 = {
    initial: {
      x: -60,
      opacity: 0,
      transition: { duration: 0.6, ease: easeing }
    },
    animate: {
      x: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };



  const [t, i18n] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element

    message == 'fr' ? i18n.changeLanguage('fr') : i18n.changeLanguage('en');


  };

  return (


    <>






      <Container class1="wrapspacefich blog-wrapper py-5 home-wrapper-fich  ">
 
        <div className="row ">



          <div className="justify-content-center justify-item-center ">
            <h2 className="text-white mb-4"> {t('Spécification-Technique')}</h2>

            <div className="row mt-3 pt-3 bg-light  roundedbg">
              <div className="row  col-sm-12 col-md-4">
                <motion.div variants={animation1} initial='initial' animate='animate' className="card-image justify-content-center text-center">
                  <HashLink to="#poducta" className=" nav-link">
                    <img src={bobine1} className="img-fluid w-50  border-col" alt="blog" />


                    <h4 className="text-dark mb-4 mt-3 "> Tôles Profilées Et Tôles Planes</h4>
                  </HashLink>


                </motion.div>
              </div>
              <div className= "col-sm-12 col-md-4">
                <motion.div variants={animation1} initial='initial' animate='animate' className="card-image justify-content-center text-center">
                  <HashLink to="#poductb" className=" nav-link">
                    <img src={bobine2} className="img-fluid w-50  border-col" alt="blog" />



                    <h4 className="text-dark mb-4 mt-3"> Tubes Moyens Soudés</h4>
                  </HashLink>



                </motion.div>
              </div>
              <div className= "col-sm-12 col-md-4">
                <motion.div variants={animation1} initial='initial' animate='animate' className="card-image justify-content-center text-center">
                  <HashLink to="#poductc" className=" nav-link">

                    <img src={bobine3} className="img-fluid w-50  border-col" alt="blog" />





                    <h4 className="text-dark mb-4 mt-3"> Tubes Minces Soudé</h4>
                  </HashLink>

                </motion.div>
              </div>


            </div>

          </div>


        </div>

        <motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poducta">



          <div className="justify-content-center ">

            <div className="row mt-3">
              <div className="row col-8">
                <motion.div className="card-image justify-content-center ">

                  <h3 className=" mb-4 mt-3 text-primary"> BOBINES GALVANISÉES</h3>
                  <p className="text-dark mb-4 ">

                  Les Tôles Profilées et Les Tôles Planes sont Destinées aux ouvrages de couverture et de Bardage de Bâtiments industriels et aux accessoires.
                  </p>

                  <p className="text-dark mb-4 ">

                  <h4  className="text-dark fw-bold">Caractéristiques Techniques :</h4>
                   
                  </p>
                  <ul>
                    <li className="text-dark">
                    <span className="text-dark fw-bold">Type :</span> acier Doux Galvanisé ou  Plaqué (LAF qualité B) NFA 323

                    </li>
                    <li className="text-dark">
                    <span className="text-dark fw-bold">Type :</span> acier Doux Galvanisé ou  Plaqué (LAF qualité B) NFA 323

                    </li>


                  </ul>



                
                  <h4  className="text-dark fw-bold">Caractéristiques Techniques:</h4>
                  <ul>
                    <li className="text-dark">
                    <span className="text-dark fw-bold">Epaisseur  :</span> 0.5mm à 2mm

                    </li>
                    <li className="text-dark">
                    <span className="text-dark fw-bold">Largueur  :</span> Tôle plane 1000mm à 1250mm

                    </li>
                    <li className="text-dark">
                    <span className="text-dark fw-bold">Longueur   :</span> Selon commande

                    </li>


                  </ul>
                  


                </motion.div>
              </div>

              <div className= "col-sm-12 col-md-4">
                <div className="card-image justify-content-center text-center">
                  <img src={bobine1} className="img-fluid w-50  border-col" alt="blog" />
                  <h4 className="text-dark mb-4 mt-3"> Tôles Profilées Et Tôles Planes</h4>
                </div>
              </div>


            </div>

          </div>


        </motion.div>

        <motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poductb">



<div className="justify-content-center ">

  <div className="row mt-3">
    <div className="row col-8">
      <motion.div className="card-image justify-content-center ">

        <h3 className=" mb-4 mt-3 text-primary"> Tubes Moyens Soudés </h3>
        <p className="text-dark mb-4 ">

        Longitudinalement Soudés par induction à Hautes Fréquences.
        </p>

        <p className="text-dark mb-4 ">

        <h4  className="text-dark fw-bold">Applications : </h4>
         
        </p>
        <ul>
          <li className="text-dark">
          <span className="text-dark fw-bold">Type  :</span> A34 - A37

          </li>
          <li className="text-dark">
          <span className="text-dark fw-bold">Norme  :</span> NFA 35 501

          </li>

          <li className="text-dark">
          <span className="text-dark fw-bold">Norme de fabrication  :</span> NFA 49 500

          </li>


        </ul>



      
        <h4  className="text-dark fw-bold">Dimension :</h4>
        <ul>
          <li className="text-dark">
          <span className="text-dark fw-bold">Diam. Min  :</span> 42mm

          </li>
          <li className="text-dark">
          <span className="text-dark fw-bold">Diam. Max  :</span> 127mm

          </li>
         


        </ul>
        


      </motion.div>
    </div>

    <div className="row  col-sm-12 col-md-4">
      <div className="card-image justify-content-center text-center">
        <img src={bobine2} className="img-fluid w-50  border-col" alt="blog" />
        <h4 className="text-dark mb-4 mt-3">Tubes Moyens Soudés</h4>
      </div>
    </div>


  </div>

</div>


</motion.div>


<motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poductc">



<div className="justify-content-center ">

  <div className="row mt-3">
    <div className="row col-8">
      <motion.div className="card-image justify-content-center ">

        <h3 className=" mb-4 mt-3 text-primary"> Tubes Minces Soudé </h3>
        <p className="text-dark mb-4 ">
              <h4  className="text-dark fw-bold">Applications :  </h4>
              Le tube est formé à froid par déformation d’une bande d’acier ; laminé à froid (L A F) ; ou laminé à chaud (L A C) dans une succession de galets formeurs puis soudés longitudinalement à haute fréquence et raclé extérieurement.
        </p>

        <p className="text-dark mb-4 ">

        <h4  className="text-dark fw-bold">Spécification : </h4>
        Acier non allié laminé à chaud selon la norme EN 10025. <br></br>

Acier laminé à froid selon la norme EN 10130.
         
        </p>


        <p className="text-dark mb-4 ">

<h4  className="text-dark fw-bold">Applications : </h4>
Les Tubes Minces Soudés électriquement sont utilisés dans les domaines ci-après :
La menuiserie Métallique, la serrurerie, l’industrie de l’automobile, les cycles et motocycles, la construction, les serres agricole et meubles métalliques. 
 
</p>
<h4  className="text-dark fw-bold">Produit fini : </h4>



        <ul>
          <li className="text-dark">
          EN 10305-3 pour le tube rond.

          </li>
          <li className="text-dark">
          EN 10305-5 pour le tube rectangle et carré.
          </li>

       


        </ul>



      
        <h4  className="text-dark fw-bold">Etat de livraison :</h4>
        <ul>
          <li className="text-dark">
          <span className="text-dark fw-bold">NOIR  :</span> +CR1 (Généralement non traité, mais apte à être recuit).

         </li>        


        </ul>

        <h4  className="text-dark fw-bold">Longueurs :</h4>
        <ul>
          <li className="text-dark">
          Sauf demande spéciale, la longueur courante de fabrication est de 6 mètres.

         </li>        


        </ul>
        


      </motion.div>
    </div>

    <div className="row  col-sm-12 col-md-4">
      <div className="card-image justify-content-center text-center">
        <img src={bobine3} className="img-fluid w-50  border-col" alt="blog" />
        <h4 className="text-dark mb-4 mt-3">Tubes Minces Soudé</h4>
      </div>
    </div>


  </div>

</div>


</motion.div>



      </Container>

























    </>
  );
};

export default Product;
