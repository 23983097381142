import React from "react";
import { NavLink, Link } from "react-router-dom";
import { HashLink  } from 'react-router-hash-link';
import { BsSearch } from "react-icons/bs";
import compare from "../images/compare.svg";
import wishlist from "../images/wishlist.svg";
import user from "../images/user.svg";
import cart from "../images/cart.svg";
import menu from "../images/menu.svg";
import inglish from "../images/drapeu/inglish.png";
import france from "../images/drapeu/france.png";
import logo from "../images/logoaltub.png";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
const HeaderNew = () => {
  const [ t, i18n ] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element
   
    message=='fr' ?  i18n.changeLanguage('fr') :  i18n.changeLanguage('en');

    
  };
  let easing = [0.6, -0.05, 0.01, 0.99];
  const transition = { duration: 1, ease: [0.6, -0.01, -0.05, 0.99] };


  const headerr = {
    initial: {
      y: -60,
      opacity: 0,
      transition: { duration: 0.05, ease: easing }
    },
    animate: {
      transition: {
        delay: 8,
        duration: 0.6,
        ease: easing

      }
    }
  };

  const stagger = {
    Animate: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.2,
        staggerDirection: 1
      }

    }


  };
  const bottom = {
    initial: {

      opacity: 0,
      x: 160,
    },
    animate: {
      opacity: 1,
      x:0,
      y: 0,
      transition: {

        transition: { delay: 5, duration: .2, ...transition }

      }
    },
  };

  return (
    <motion.div 
    
     
     >
      <header className="header-top-menu py-3">
      <motion.div initial='initial' animate='animate'>
      <Navbar bg="light" expand="lg"  >
      <Container xxl>
      <div className="card-image">
                  <img src={logo} className="img-fluid w-50" alt="" />
                </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end  text-capitalize text-danger"  >
          <Nav 
            className="ml-auto my-3 mx-2 my-lg-0 "
            style={{ maxHeight: '250px' }}
            navbarScroll
            bg="dark"
          >
          
              <div className="text-dark"   >
            <NavLink to="/" className=" nav-link">
            {t('Accueil')}</NavLink>
              </div >
           
                   
                        
                

            <NavDropdown title= {t('Qui-Sommes-Nous')} id="navbarScrollingDropdown">
              
            <NavDropdown.Item >
           
           <div className="text-dark"   >
         <NavLink to="/quisomenous" className=" nav-link">
         {t('Qui-Sommes-Nous')}</NavLink>
           </div >
        </NavDropdown.Item>
              
              <NavDropdown.Item >  
              <div className="text-dark"   >
              <HashLink to="/#implantationid" className=" nav-link">
              {t('Implantation')}</HashLink>
              </div >
              
              
           </NavDropdown.Item>
              
              
              
              <NavDropdown.Item >
         
              <div className="text-dark"   >
            <HashLink to="/#motdepresidentid" className=" nav-link">
            {t('MotdePresedent')  }</HashLink>
              </div >

              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item >
         
              <div className="text-dark"   >
            <NavLink to="politique-qualitie" className=" nav-link">
            {t('Politique-Qualite')}</NavLink>
              </div >
              
              </NavDropdown.Item>
              <NavDropdown.Item href="#action5">
              <div className="text-dark"   >
            <NavLink to="certification" className=" nav-link">
            {t('Certification')}</NavLink>
              </div >
              
             
              </NavDropdown.Item>
              <NavDropdown.Item to="Home">
          


                <div className="text-dark"   >
              <HashLink to="/#calerieid" className=" nav-link">
              {t('Galerie')}</HashLink>
              </div >
              
              </NavDropdown.Item>
            </NavDropdown>


            <NavDropdown  title={t('Produits')} id="navbarScrollingDropdown">
             
            <NavDropdown.Item >
           
           <div className="text-dark"   >
         <NavLink to="product" className=" nav-link">
         {t('Produits')}</NavLink>
           </div >
           </NavDropdown.Item>

            

              <NavDropdown.Item >
           
              <div className="text-dark"   >
            <NavLink to="fiche-technique" className=" nav-link">
            {t('Fiche-Technique')}</NavLink>
              </div >
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item >

              <div className="text-dark"   >
            <NavLink to="specif-technique" className=" nav-link">
            {t('specif-technique')}</NavLink>
              </div >
             
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Contact" id="navbarScrollingDropdown">
          
              <NavDropdown.Item >
           

               <div className="text-dark"   >
            <HashLink to="/#contactid" className=" nav-link">
            {t('Contactez-nous')  }</HashLink>
              </div >

              
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item >
                
             <div className="text-dark"   >
              <HashLink to="/#recrutementid" className=" nav-link">
              {t('Recrutement')}</HashLink>
              </div >
              
              </NavDropdown.Item>
            </NavDropdown>


            
            <div className="text-dark" variants={bottom}  >
            <NavLink to="/" className=" nav-link">
            {t('References')}</NavLink>
              </div >
          
                    {i18n.language=='en'&& 

                    <img src={france} alt=""  className="img-fluid img-fluid img-thumbnail w-5 imagepht  rounded me-3" 
                            onClick={(event) => handleClick(event, 'fr')} 
                    />}
                     {i18n.language=='fr'&& 
                    <img src={inglish} alt="" className="img-fluid img-fluid img-thumbnail w-5 imagepht rounded me-1" 
                           onClick={(event) => handleClick(event, 'en')}
                    />}
                   
                      
                 

          
          </Nav>
         
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </motion.div>
    </header>
 
    </motion.div>
  );
};

export default HeaderNew;
