import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Container from "../components/Container";
import { motion } from "framer-motion";







//import { services } from "../utils/Data";

const FicheTechnique = (props) => {



  const [ t, i18n ] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element
   
    message=='fr' ?  i18n.changeLanguage('fr') :  i18n.changeLanguage('en');

    
  };

  return (


    <>






      <Container class1="wrapspacefich blog-wrapper py-5 home-wrapper-fich  ">
      <div className="row ">
    
      

        <motion.div className="justify-content-center "
        
        initial={{opacity:0, scale:0 }}
        animate={{opacity:1, scale:1}}
        transition={{duration:0.8}}
        
        >
        <h2 className="text-white mb-3"> {t('Fiche-Technique')}</h2>
          <h4 className="text-white mb-4"> {t('CARACTERISTIQUES')}<strong> :</strong></h4>
          <p className="text-white mb-4 text-sm-start">

          {t('fichepara1')}
          </p>

          <h4 className="text-white mb-3">  {t('UTILISATIONS')} <strong> :</strong></h4>
          <p>
          {t('fichepara2')}


          </p>
          <table style={{
            overflowX:"auto"
          }} >
            <tbody>
              <tr>
                <th>
                  Produits

                </th>
                <th>
                  Applications

                </th>
                <th>
                  Procédé de fabrication

                </th>
                <th>
                  Caractéristiques
                  Techniques

                </th>
                <th>
                  Dimension

                </th>

              </tr>
              <tr style={{backgroundColor:'#ffffff'}} className="text-dark">
                <td className="text-dark " >
                Tôles Profilées Et Tôles planes

                </td>
                <td className="text-dark " >
                Bâtiment, construction, transport, équipement agricole, mobilie

                </td>
                <td className="text-dark " >
                 -

                </td>
                <td className="text-dark " >
                Type : acier Doux
Galvanisé ou  Plaqué
(LAF qualité B)
Norme :NFA 36 321

                </td>
                <td className="text-dark " >
                Type : acier Doux
Galvanisé ou  Plaqué
(LAF qualité B)
Norme : NFA 36 321

                </td>

              </tr>

              <tr style={{backgroundColor:'#ffffff'}} className="text-dark">
                <td className="text-dark " >
                Tubes Moyens Soudés Longitudinalement Soudés par induction à Haute Fréquence

                </td>
                <td className="text-dark " >
                Tubes de qualités 102 et 103 De forme Ronde, Carrée, et Rectangulaire.
Les Tubes Minces Soudés électriquement sont utilisés dans les domaines ci-après :

                </td>
                <td className="text-dark " >
                Le tube est formé à froid par déformation d’une bande d’acier ; laminé à froid (L A F) ; ou laminé à chaud (L A C) dans une succession de galets formeurs puis soudés longitudinalement à haute fréquence et raclé extérieurement.

                </td>
                <td className="text-dark " >
                Acier non allié laminé à chaud selon la Norme EN 10025.

                </td>
                <td className="text-dark " >
                Sauf demande spéciale, la longueur courante de fabrication est de 6 mètres.

                </td>

              </tr>
              <tr style={{backgroundColor:'#ffffff'}} className="text-dark">
                <td className="text-dark " >
                Tubes minces Soudés par induction à Haute Fréquence

                </td>
                <td className="text-dark " >
                La menuiserie Métallique, la serrurerie, l’industrie de l’automobile, les cycles et motocycles, la construction, les serres agricole et meubles métalliques.

                </td>
                <td className="text-dark " >
                 

                </td>
                <td className="text-dark " >
                Acier laminé à froid selon la Norme EN 10130.

                </td>
                <td className="text-dark " >
             

                </td>

              </tr>
            </tbody>
          </table>




          <p><strong>.Notre tube </strong>
           est formé  par déformation d’une bande d’acier ; laminé à froid (L A F) ; ou laminé à chaud (L A C) dans une succession de galets formeurs puis soudés longitudinalement à haute fréquence et raclé extérieurement.


          </p>

          <p><strong>. Tôles nervurées : </strong><br></br>
          Galvanisées et pré laquées <br></br>
Selon norme NFA 36 321<br></br>
Application : Tôles destinées aux ouvrages de couverture, bardage des bâtiments industriels.

          </p>

          <p><strong>. Tôles :</strong><br></br>
          Galvanisées, pré laquées et noires<br></br>
Selon norme NFA 36 321<br></br>
Application : Ferronnerie, Serrurerie, Bâtiment industriel.<br></br>

          </p>

          </motion.div>
       

        </div>
      </Container>

























    </>
  );
};

export default FicheTechnique;
