import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import { AiOutlineHome, AiOutlineMail } from "react-icons/ai";
import { BiPhoneCall, BiInfoCircle } from "react-icons/bi";
import Container from "../components/Container";
import "./MPrecident.css";
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const Galerie = () => {
  const [t, i18n] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element

    message == 'fr' ? i18n.changeLanguage('fr') : i18n.changeLanguage('en');


  };
  return (
    <>
      <Meta title={"Mot Du Président"} />
      <BreadCrumb classnamecolor="text-dark" title={t('MotdePresedent')} />
      <Container class1="description-wrapperprecedent py-5 home-wrapper-2"  >
        <div className="row" >
          <div className="col-12">


            <motion.div className="bg-white p-3"  
            initial={{opacity:0, scale:0, x:500}}
            animate={{opacity:1, scale:1, x:0}}
            transition={{duration:0.6}}
            //viewport={{once:true}}
            >
              <p>{t('Mot-Precedentp1')}</p>
              <p>
                <ul >
                  <li >{t('Prd1')}
                  </li>
                  <li>{t('Prd2')}</li>
                  <li>{t('Prd3')}</li>
                  <li>{t('Prd4')}</li>
                  <li>{t('Prd5')}</li>
                </ul>
                {t('Mot-Precedentp2')}
              </p><br></br>






              <figure class="text-end">
                <blockquote class="blockquote">
                  <p class="fw-bolder">RAHMOUNE Smail</p>
                </blockquote>
                <figcaption class="blockquote-footer">
                  {t('post1')}<cite title="Source Title"></cite>
                </figcaption>
              </figure>


            </motion.div>


          </div>
        </div>
      </Container>
    </>
  );
};

export default Galerie;
