import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Container from "../components/Container";

import bobine2 from "../images/bobine/bobine1.jpg";
import bobine1 from "../images/ent/Entreprise.jpg";
import bobine3 from "../images/bobine/bobine3.jpg";
import { HashLink } from 'react-router-hash-link';
import { motion } from "framer-motion";
import ContactNous from "./ContactNous";








//import { services } from "../utils/Data";

const QuiSomeNousEng = (props) => {

  let easeing = [0.6, -0.05, 0.01, 0.99];
  const animation1 = {
    initial: {
      y: -60,
      opacity: 0,
      transition: { duration: 0.6, ease: easeing }
    },
    animate: {
      y: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };


  const animation2 = {
    initial: {
      x: -60,
      opacity: 0,
      transition: { duration: 0.6, ease: easeing }
    },
    animate: {
      x: 0,
      opacity: 1,
      animation: {
        duration: 0.6,
        ease: easeing
      }
    }
  };



  const [t, i18n] = useTranslation();

  const handleClick = (event, message) => {
    // 👇️ refers to the image element

    message == 'fr' ? i18n.changeLanguage('fr') : i18n.changeLanguage('en');


  };
  const navigate = useNavigate();

  useEffect(() => {
   
    if( i18n.language=='fr' ) {
     navigate("/quisomenous")
     
    // alert(params.toString())
 
    }
    
 
   }, [i18n.language]);
 
 

  return (


    <>






      <Container class1="wrapspacefich blog-wrapper py-5 home-wrapper-fich  ">



        <motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poducta">



          <div className="justify-content-center ">

            <div className="row mt-3">
              <div className="row col-sm-12 col-md-8">
                <h2 className="text-dark mb-4"> Presentation :</h2>
                <motion.div className="card-image justify-content-center ">


                  <p className="text-dark mb-4 ">

                  Algerian society tube manufacturing to industrial and commercial vocation, belonging to RAHMOUNE group represented by its Chief Executive Officer Mr. Ismail RAHMOUNE is located in the industrial area of Oued Smar lot 178, created in 2003 , currently employs more than one hundred person with a duty cycle of two teams ( two times eight). Savoir faire : Grâce à des années d’expérience avec un équipement de haute technologie et un personnel qualifié, Altub offre une gamme de produits destinés aux industriels et aux consommateurs professionnels,
                  </p>





                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">Expertise :</span><br></br>
                    Through years of experience with high-tech equipment and qualified personnel Altub offers a range of products for industrial and professional users,
                  </p>



                </motion.div>
              </div>

       
              <motion.div variants={animation1} initial='initial' animate='animate' className="row col-sm-12 col-md-4 ">
                <div className="card-image justify-content-center text-center py-4">
                  <img src={bobine1} className="img-fluid w-75  " alt="blog" />

                </div>
              </motion.div>


            </div>

          </div>


        </motion.div>

        <motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poductb">



          <div className="justify-content-center ">

            <div className="row mt-3">
              <div className="row col-12">
                <div className="card-image justify-content-center ">


                  <p className="text-dark mb-4 ">
                  Altub quickly established itself in its field and is today recognized for the quality of its products as a national leader in the manufacture of steel tubes of square, rectangular and round with different thicknesses in accordance with international standards and requirements forms.
                   
                    </p>


                  <p className="text-dark mb-4 ">

                    <span className="text-dark fw-bold">Development / product : </span><br></br>
                    The main objective is to develop more efficient processes and products more responsive to markets.<br></br>
                    Currently covering the entire national territory through effective distribution and Altub successful commercial service is short-term, increase its share of local market share and face the external markets (targeted Maghreb and African).

                  </p>






                </div>
              </div>




            </div>

          </div>


        </motion.div>

        <motion.div className="row bg-light mt-2 roundedbg " variants={animation2} initial='initial' animate='animate' id="poductb">



          <div className="justify-content-center ">
          <h2 className="text-dark mb-4 mt-4">Company:</h2>

            <div className="row mt-3">
              <div className="row col-12">
                <div className="card-image justify-content-center ">
               <p className="mb-1"> <span className="text-dark fw-bold ">Statut et forme juridique</span></p><br></br>
              <p className="mb-1">  <span className="text-dark fw-bold ">Sarl, ALTUB:</span></p><br></br>
                <p className="text-dark mb-4 ">

<span className="text-dark fw-bold">Raison sociale : </span>
Société Algérienne de Fabrication de Tubes
</p>
<p className="text-dark mb-4 ">

<span className="text-dark fw-bold">Tel : </span>
+ 213 21 51 34 14 – 21 51 53 85
</p>
<p className="text-dark mb-4 ">

<span className="text-dark fw-bold">Fax : </span>
+213 21 51 39 67
</p>
<p className="text-dark mb-4 ">

<span className="text-dark fw-bold">Email : </span>
contact@altub-dz.com
</p>
<div className="col-12 bg-dark mb-4">
<table style={{
             width: '100%'
          }} >
            <tbody>
              <tr >
                <th colSpan={2}>
                  
Implantation :

                </th>
                
               
               

              </tr>
              <tr style={{backgroundColor:'#ffffff'}} className="text-dark">
                <td className="text-dark " >
                Superficie totale: 

                </td>
                <td  className="text-dark " >
                25.000 m2
                </td>
           
            
               

              </tr>

              <tr style={{backgroundColor:'#ffffff'}} className="text-dark">
                <td className="text-dark " >
                Superficie couverte :

                </td>
                <td className="text-dark " >
                15.000 m2
                </td>
           
            
               

              </tr>

              <tr style={{backgroundColor:'#ffffff'}} className="text-dark">
              <td className="text-dark " >
                Bloc administratif :

                </td>
                <td className="text-dark " >
                400 m2 sur 3 niveaux

                </td>

             

              </tr>
              <tr style={{backgroundColor:'#ffffff'}} className="text-dark">
                <td className="text-dark " >
                Annexe Bloc administratif :

                </td>
                <td className="text-dark " >
                320 m2
                </td>
               
               
              </tr>
            </tbody>
          </table>


          </div>





                </div>
              </div>




            </div>

          </div>


        </motion.div>

        <motion.div

          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.9 }}

        >

          <div className="col-12 bg-light roundedbg mt-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3198.853844514343!2d3.1601980507913727!3d36.70204988082003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128e523410856bb5%3A0x494b8f4857a79b39!2sALTUB!5e0!3m2!1sfr!2sdz!4v1680534829148!5m2!1sfr!2sdz"
              width="600"
              height="450"
              className="border-0 w-100"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </motion.div>


      </Container>
























    </>
  );
};

export default QuiSomeNousEng;
