import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = (props) => {
  const { title, classnamecolor } = props;
 

  const classname= props.classnamecolor ? " text-center mb-0 " +props.classnamecolor
                    : "text-center mb-0" ;

  return (
    <div className="breadcrumb mb-0 py-4">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12">
            <p className={classname}>
          
               {title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
