import { Helmet } from "react-helmet";
import React from "react";

const Meta = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <meta name="description" content="Société Algérienne de Fabrication de Tubes" />
      <meta name="keywords" content="altub,lot n°40, Zone Industrielle, Oued Smar 16059  Alger" />
    </Helmet>
  );
};

export default Meta;
